import { render, staticRenderFns } from "./memorandum.vue?vue&type=template&id=2f4ffd94&scoped=true"
import script from "./memorandum.vue?vue&type=script&lang=js"
export * from "./memorandum.vue?vue&type=script&lang=js"
import style0 from "./memorandum.vue?vue&type=style&index=0&id=2f4ffd94&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f4ffd94",
  null
  
)

component.options.__file = "memorandum.vue"
export default component.exports